import React, { useEffect } from "react";
import Prism from "prismjs";
import SgLayout from "~layouts/sg-layout";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import TestimonialCarousel from '~2-components/TestimonialCarousel/TestimonialCarousel';
import { dummyTestimonials } from '~data/dummyTestimonials';
import { Link, useStaticQuery, graphql } from 'gatsby';

const testimonialCarouselEg = `import TestimonialCarousel from '~2-components/TestimonialCarousel/TestimonialCarousel';

<TestimonialCarousel
    items=[] 
/>
`;

const SgTestimonialCarousel = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    const testimonialCarousels = useStaticQuery(
        graphql`
            query SGAllTestimonialCarousels {
                allKontentItemTestimonialPanel {
                    nodes {
                        system {
                            id
                            type
                        }
                    }
                }
            }
    `);

    const allTestimonialCarousels = testimonialCarousels.allKontentItemTestimonialPanel.nodes;

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Testimonial Carousel</h1>
                    <p>Uses a <Link to="/styleguide/components/slider-container/">Slider Container</Link> but with custom styling.</p>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={testimonialCarouselEg}
                    />

                    <h3>Example</h3>
                </div>
            </section>
            <TestimonialCarousel
                testimonials={dummyTestimonials}
            />
            <section className="sg-content">
                <div className="container">
                    <h3>All LIVE Kontent testimonial carousels</h3>
                </div>
            </section>
            {allTestimonialCarousels.map((item, i) => {
                return (
                    <TestimonialCarousel
                        key={i}
                        {...item}
                    />
                );
            })}
        </SgLayout>
    );
};

export default SgTestimonialCarousel;
